import api from "./api"

export const APP_USER_KEY = "@realyticsAuth-AppUserKey";
export const isAuthenticated = () => localStorage.getItem(APP_USER_KEY) !== null;

// auth_level1 - Level para Usuários Realytics
// auth_level2 - Level para Usuários Realytics/Administradores
// auth_level3 - Level para Usuários Realytics/Administradores/Retaguarda
// auth_level4 - Todos
export const getLevelAccess = (level) => {
  switch (level) {
    case "level1":
      return ((getUser().isRealytics === 1))
    case "level2":
      return ((getUser().isRealytics === 1) || (getUser().isAdmin === 1));
    case "level3":
      return ((getUser().isRealytics === 1) || (getUser().isAdmin === 1) || (getUser().isBackOffice === 1));
    default:
      return true;
  }
}

export const getSolutionAccess = (solutionName) => { 
  if (solutionName === '') {
    return true;
  }

  //Busca dados no cache
  var rawData = localStorage.getItem(APP_USER_KEY);
  if (rawData !== null) {
    var data = JSON.parse(rawData);

    if (data.user.isRealytics === 1) {
      return true;
    } else {
      if (data.institutions.length === 0) {
        return false;
      } else {
        if (solutionName === 'itsm') {
          return true;
        } else {
          return data.companies[solutionName].length > 0;
        }
      }
      
    }
  } else {
    return false;
  }
  //
}

// Implementação de rotina para validar token.
// Ao validar token, atualiza cache no novo token gerado
// Se não validar, devolve negativa e inicia o processo de logout
export const validateToken = async () => {
  var currentUser = getUser(), retorno = false;
  if (currentUser !== null) {
    let token = currentUser.token;

    try {
      var route = `/customerusers/validatetoken/${token}`;
      const response = await api.get(route);
      if (response.data) {
          if (!response.data.error) {
            retorno = true;

            //Atualiza cache com novo token
            var rawData = localStorage.getItem(APP_USER_KEY);
            if (rawData !== null) {
              //Abre json salvo, altera token e salva novamente
              var data = JSON.parse(rawData);
              data.token = response.data.refreshToken;
              localStorage.setItem(APP_USER_KEY, JSON.stringify(data));
            }
          }
      }
    } catch (e) {
        console.log(e);
    }
  }
  
  return retorno;
};

export const getToken = () => {
  var output = null, currentUser = getUser();
  if (currentUser !== null) {
    output = currentUser.token;
  }
  return output;
};

export const getUser = () => {
  var output = null, rawData = localStorage.getItem(APP_USER_KEY);
  if (rawData !== null) {
    var data = JSON.parse(rawData), currentUser = data.user;
    output = currentUser;
  }
  return output;
};

export const getUserData = () => {
  var output = null, rawData = localStorage.getItem(APP_USER_KEY);
  if (rawData !== null) {
    var data = JSON.parse(rawData), currentUser = data;
    output = currentUser;
  }
  return output;
};

export const getUserInstitutionIds = () => {
  var output = [], rawData = localStorage.getItem(APP_USER_KEY);
  if (rawData !== null) {
    var data = JSON.parse(rawData), currentUserInstitutions = data.institutions;
    for(var i = 0; i < currentUserInstitutions.length; i++){
      output.push(currentUserInstitutions[i].sK_CLIENTE_REALYTICS);
    }
  }
  return output;
};

export const getUserSolutionInstitutionIds = (solutionName) => {
  var output = [], rawData = localStorage.getItem(APP_USER_KEY);
  if (rawData !== null) {
    var data = JSON.parse(rawData), companies = data.companies[solutionName];
    for(var i = 0; i < companies.length; i++){
      if (!output.includes(companies[i].sK_CLIENTE_REALYTICS)) {
        output.push(companies[i].sK_CLIENTE_REALYTICS);
      }
    }
  }
  return output;
};

export const login = userInfo => {
  console.log(userInfo.data)
  var userToSave = JSON.stringify(userInfo.data);
  localStorage.setItem(APP_USER_KEY, userToSave);
};

export const logout = () => {
  localStorage.removeItem(APP_USER_KEY);
};